export default {
  name: "DynamicBackground",
  props: {
    transitionTime: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      images: [
        "https://res.cloudinary.com/setyawan/image/upload/v1623978176/Prewedding/gallery/DRA_7117_ec8vq5.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978179/Prewedding/gallery/DRA_7131_jihmbs.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978177/Prewedding/gallery/DRA_7158_e1bhca.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978177/Prewedding/gallery/DRA_7176_mhiypm.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978178/Prewedding/gallery/DRA_7231_xlfyqs.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978177/Prewedding/gallery/DRA_7414_qtvdf9.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978177/Prewedding/gallery/DRA_7426_ezr4mi.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978179/Prewedding/gallery/DRA_7454_vjoxsq.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978178/Prewedding/gallery/DRA_7488_am4azh.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978178/Prewedding/gallery/DRA_7502_ohbbqb.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978178/Prewedding/gallery/DRA_7521_bha4u1.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978181/Prewedding/gallery/DRA_7583_tzsmyi.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978179/Prewedding/gallery/DRA_7590_dfhsws.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978179/Prewedding/gallery/DRA_7642_ut19gc.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978180/Prewedding/gallery/DRA_7656_wils6z.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978183/Prewedding/gallery/DRA_7793_bhtuhw.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978181/Prewedding/gallery/DRA_7826_qveefw.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978180/Prewedding/gallery/DRA_7854_urcxbr.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978182/Prewedding/gallery/DRA_7861_mdjafq.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978182/Prewedding/gallery/DRA_7868_a5rtd7.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978181/Prewedding/gallery/DRA_7874_nmplxs.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978182/Prewedding/gallery/DRA_7889_k3ykhv.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978183/Prewedding/gallery/DRA_7894_p2q84l.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978183/Prewedding/gallery/DRA_7901_fuubzy.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_7905_dxtnnq.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978183/Prewedding/gallery/DRA_7915_kpbvi9.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_7954_nll4d2.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_7965_gkwwby.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978186/Prewedding/gallery/DRA_8018_dysico.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_8019_p8hbjn.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_8022_yh7n5c.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_8030_m6vubk.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978185/Prewedding/gallery/DRA_8038_eof2td.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978185/Prewedding/gallery/DRA_8042_yc5xlg.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978185/Prewedding/gallery/DRA_8056_ojhtap.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978185/Prewedding/gallery/DRA_8065_jlqszn.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978185/Prewedding/gallery/DRA_8073_eut7cy.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978186/Prewedding/gallery/DRA_8075_vn16gi.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978186/Prewedding/gallery/DRA_8079_zdtfvg.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978187/Prewedding/gallery/DRA_8082_zap21g.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978162/Prewedding/gallery/DRA_7089_s4flpx.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978163/Prewedding/gallery/DRA_7097-2_a6tc7p.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978171/Prewedding/gallery/DRA_7127_gqmyiq.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978161/Prewedding/gallery/DRA_7164_vvmkpg.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978162/Prewedding/gallery/DRA_7188_rxjtx5.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978162/Prewedding/gallery/DRA_7192_e3slr5.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978163/Prewedding/gallery/DRA_7211_krzuka.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978163/Prewedding/gallery/DRA_7240_tvbg9p.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978164/Prewedding/gallery/DRA_7253_wfjzh6.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978164/Prewedding/gallery/DRA_7265_ecqwrv.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978164/Prewedding/gallery/DRA_7321_o5brdn.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978164/Prewedding/gallery/DRA_7329_cbyy2a.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978168/Prewedding/gallery/DRA_7367_mcryjj.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978165/Prewedding/gallery/DRA_7401_h2o08o.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978165/Prewedding/gallery/DRA_7483_hw2ocf.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978168/Prewedding/gallery/DRA_7588_dosdue.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978171/Prewedding/gallery/DRA_7593_k1hajz.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978165/Prewedding/gallery/DRA_7637_hw8nf4.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978168/Prewedding/gallery/DRA_7644_wmfb2w.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978168/Prewedding/gallery/DRA_7677_ygzjbe.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978174/Prewedding/gallery/DRA_7702_bsk0va.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978169/Prewedding/gallery/DRA_7717_itne64.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978170/Prewedding/gallery/DRA_7728_vkdiph.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978170/Prewedding/gallery/DRA_7736_wix1jz.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978170/Prewedding/gallery/DRA_7750_fpycnr.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978171/Prewedding/gallery/DRA_7822_jcrhld.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978171/Prewedding/gallery/DRA_7883_c9hjkd.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978171/Prewedding/gallery/DRA_7922_paxgub.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978159/Prewedding/gallery/DRA_7931_dboxvf.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978159/Prewedding/gallery/DRA_7945_blhegb.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978159/Prewedding/gallery/DRA_7952_mgvukf.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978159/Prewedding/gallery/DRA_7962_b95vzy.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978160/Prewedding/gallery/DRA_7988_ty01yb.jpg",
        "https://res.cloudinary.com/setyawan/image/upload/v1623978164/Prewedding/gallery/DRA_8004_t0iela.jpg",
      ],
      mainImageSrc: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let idx = 0;
      this.changeImage(idx);
      setInterval(() => {
        if (idx === this.images.length - 1) {
          idx = 0;
        } else {
          idx += 1;
        }
        this.changeImage(idx);
      }, this.transitionTime);
    },
    changeImage(index) {
      const img = this.images[index];
      this.mainImageSrc = `url('${img}')`;
    },
  },
};
