import { QrcodeStream } from "vue-qrcode-reader";
import screenfull from "screenfull";
import { isValidUUID } from "@/utils/uuid";
import supabase from "@/utils/supabase";
import { app } from "@/config";
import DynamicBackground from "@/components/DynamicBackground.vue";

export default {
  name: "App",
  data() {
    return {
      camera: "front",
      cameraTemp: null,
      isValid: undefined,
      value: null,
      error: null,
      timeoutId: 0,
      audio: new Audio("/tone.mp3"),
    };
  },
  components: {
    QrcodeStream,
    DynamicBackground,
  },
  computed: {
    name() {
      return this.value?.name;
    },
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },
    validationSuccess() {
      return this.isValid === true;
    },
    validationFailure() {
      return this.isValid === false;
    },
    isFrontCamera() {
      return this.camera === "front";
    },
  },
  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        console.error(error);
      }
    },
    async onDecode(decodedString) {
      try {
        this.turnCameraOff();
        this.isValid = isValidUUID(decodedString);
        if (this.isValid) {
          await this.readGuest(decodedString);
        } else {
          await this.timeout(50);
          this.$toasted.global.mwError({ message: "Invalid QR Code" });
        }
        this.turnCameraOn();
        this.resetValidationState();
      } catch (error) {
        this.error = error;
      }
    },
    turnCameraOn() {
      this.camera = this.cameraTemp;
    },
    turnCameraOff() {
      this.cameraTemp = this.camera;
      this.camera = "off";
    },
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
      }
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    playTone() {
      this.audio.play();
    },
    resetValidationState() {
      this.isValid = undefined;
    },
    resetValueState() {
      if (this.timeoutId >= 0) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = window.setTimeout(() => {
        this.value = null;
        this.error = null;
      }, app.delayTime);
    },
    async readGuest(id) {
      try {
        const { data: guest, error } = await supabase
          .from(app.dbName)
          .select("*")
          .eq("id", id);
        if (error) {
          this.$toasted.global.mwError({ message: error.message });
        } else if (!guest?.length) {
          this.$toasted.global.mwError({ message: "ID tidak ditemukan" });
        } else if (guest?.[0]?.checkInDate !== null) {
          this.$toasted.global.mwInfo({
            message: `${guest[0].name} Anda sudah pernah check in pada ${guest[0].checkInDate}`,
          });
        } else {
          this.afterReadGuest(guest[0]);
        }
      } catch (error) {
        this.error = error;
      }
    },
    async checkInGuest(id) {
      try {
        const { data: guest, error } = await supabase
          .from(process.env.VUE_APP_DB_NAME)
          .update({ checkInDate: new Date() })
          .eq("id", id);
        if (error) {
          this.$toasted.global.mwError({ message: error.message });
        } else {
          this.$toasted.global.mwSuccess({
            message: `Selamat datang ${guest[0].name}`,
          });
        }
      } catch (error) {
        this.error = error;
      }
    },
    async afterReadGuest(guest) {
      if (!guest.checkInDate) {
        this.value = guest;
        await this.checkInGuest(this.value.id);
        this.playTone();
      }
      this.resetValueState();
    },
    async removeCheckInGuest(id) {
      try {
        const { data: guest, error } = await supabase
          .from(process.env.VUE_APP_DB_NAME)
          .update({ checkInDate: null })
          .eq("id", id)
          .not("checkInDate", "is", null);
        if (error) {
          this.$toasted.global.mwError({ message: error.message });
        } else {
          this.value = guest[0];
          this.$toasted.global.mwSuccess({
            message: `Remove check in ${this.name}`,
          });
        }
      } catch (error) {
        this.error = error;
      }
    },
    fullscreen() {
      if (screenfull.isEnabled) {
        screenfull.toggle();
      }
    },
  },
};
